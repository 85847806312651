@import '../variables';
@import './fonts';

@mixin _button-disabled($font-color, $background-color) {
  &:disabled,
  &[disabled='true'],
  &.disabled {
    cursor: not-allowed;

    background-color: $background-color;
    color: $font-color;

    lefty-icon,
    glyph,
    .icon {
      color: $font-color;
    }

    lefty-spinner {
      border-color: currentColor !important;
    }
  }
}

@mixin _button(
  $font-color,
  $background-color,
  $background-color-hover,
  $icon-color: $font-color,
  $spinner-color: $icon-color
) {
  background-color: $background-color;
  color: $font-color;

  lefty-icon,
  glyph,
  .icon {
    color: $icon-color;
  }

  lefty-spinner {
    border-color: $spinner-color !important;
  }

  &:hover {
    background-color: $background-color-hover;

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }
  }
}

@mixin button-base {
  font-size: var(--button-font-size);
  line-height: var(--button-line-height);
  font-family: inherit;
  font-weight: var(--button-font-weight);
  border: none;
  box-sizing: border-box;
  transition:
    background-color 0.2s,
    color 0.2s,
    border-color 0.2s;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;

  &:focus,
  &.focus {
    outline-offset: 1px;
    outline: solid var(--button-focus-border-size) var(--button-focus-color) !important;
  }

  /// START Size and Layout
  ///////////////////
  .lefty-icon-i {
    font-size: var(--button-icon-size) !important;
  }

  lefty-spinner {
    width: var(--button-spinner-size) !important;
    height: var(--button-spinner-size) !important;
    margin-right: $grid-space-2;

    .circle::before {
      border-width: 2px !important;
    }
  }

  &:not(.plain) {
    lefty-spinner {
      margin-left: -$grid-space-1;
    }

    &.left-icon {
      lefty-icon,
      glyph,
      .icon {
        margin-left: -$grid-space-1;
      }
    }
  }

  &.icon {
    padding: 0 !important;

    lefty-spinner {
      margin: 0;
    }
  }

  &.left-icon {
    lefty-icon,
    glyph,
    .icon {
      margin-right: $grid-space-1;

      i {
        margin: 0 !important;
      }
    }
  }

  &.right-icon {
    lefty-icon,
    glyph,
    .icon {
      margin-left: $grid-space-1;
      margin-right: -$grid-space-1;

      i {
        margin: 0 !important;
      }
    }
  }
}

@mixin button-primary {
  @include _button(
    $font-color: var(--button-primary-font-color),
    $background-color: var(--button-primary-background-color),
    $background-color-hover: var(--button-primary-hover-background-color),
    $icon-color: var(--button-primary-icon-color),
    $spinner-color: var(--button-primary-spinner-color)
  );

  @include _button-disabled(
    $font-color: var(--button-primary-disabled-font-color),
    $background-color: var(--button-primary-disabled-background-color)
  );
}

@mixin button-secondary {
  @include _button(
    $font-color: var(--button-secondary-font-color),
    $background-color: var(--button-secondary-background-color),
    $background-color-hover: var(--button-secondary-hover-background-color),
    $icon-color: var(--button-secondary-icon-color),
    $spinner-color: var(--button-secondary-spinner-color)
  );

  @include _button-disabled(
    $font-color: var(--button-secondary-disabled-font-color),
    $background-color: var(--button-secondary-disabled-background-color)
  );

  &.subtle {
    @include button-subtle;
  }

  &.ghost {
    @include button-ghost;
  }
}

@mixin button-subtle {
  @include _button(
    $font-color: var(--black),
    $background-color: var(--grey-050),
    $background-color-hover: var(--grey-080),
    $icon-color: var(--grey-600),
    $spinner-color: var(--grey-400)
  );

  @include _button-disabled(
    $font-color: var(--grey-180),
    $background-color: var(--grey-020)
  );
}

@mixin button-ghost {
  @include _button(
    $font-color: var(--black),
    $background-color: transparent,
    $background-color-hover: var(--grey-050),
    $icon-color: var(--grey-600),
    $spinner-color: var(--grey-400)
  );

  @include _button-disabled(
    $font-color: var(--grey-180),
    $background-color: transparent
  );
}

@mixin button-caution {
  @include _button(
    $font-color: var(--red-400),
    $background-color: var(--red-050),
    $background-color-hover: var(--red-100)
  );

  @include _button-disabled(
    $font-color: var(--grey-400),
    $background-color: var(--grey-050)
  );
}

@mixin button-destructive {
  @include _button(
    $font-color: white,
    $background-color: var(--red-400),
    $background-color-hover: var(--red-600)
  );

  @include _button-disabled(
    $font-color: var(--grey-400),
    $background-color: var(--grey-050)
  );
}

@mixin button-plain {
  padding: 0;
  height: var(--button-plain-height);

  lefty-spinner:not(.icon) {
    margin-right: $grid-space-1;
    margin-left: 0;
  }

  &.icon {
    padding: 0 !important;
    width: var(--button-plain-height);
    min-width: var(--button-plain-height);

    lefty-spinner {
      margin: 0;
    }
  }

  &.underline {
    text-decoration: underline;
  }

  &:not(.dark) {
    @include _button(
      $font-color: var(--button-plain-font-color),
      $background-color: transparent,
      $background-color-hover: transparent,
      $spinner-color: var(--button-plain-spinner-color)
    );

    &:hover:not(:disabled):not(.disabled) {
      color: var(--button-plain-hover-background-color);
    }
  }

  &.dark {
    @include _button(
      $font-color: var(--button-plain-dark-font-color),
      $background-color: transparent,
      $background-color-hover: transparent,
      $icon-color: var(--button-plain-dark-icon-color),
      $spinner-color: var(--button-plain-dark-spinner-color)
    );

    &:hover:not(:disabled):not(.disabled) {
      &:not(.danger) {
        color: var(--button-plain-dark-hover-background-color);
      }

      &.danger {
        color: var(--danger-color);
      }
    }
  }

  @include _button-disabled(
    $font-color: var(--grey-300),
    $background-color: transparent
  );

  &:hover {
    lefty-icon,
    glyph,
    .icon {
      color: inherit;
    }

    lefty-spinner {
      border-color: currentColor !important;
    }
  }
}

@mixin button-outline {
  @include _button(
    $font-color: var(--black),
    $background-color: transparent,
    $background-color-hover: transparent,
    $icon-color: var(--grey-600),
    $spinner-color: var(--grey-400)
  );

  @include _button-disabled(
    $font-color: var(--grey-400),
    $background-color: var(--grey-020)
  );

  border: solid var(--button-border-size) var(--grey-180);

  &:hover {
    border-color: var(--grey-300);
  }

  &:active,
  &.active {
    border-color: var(--primary-color);
  }

  &:disabled,
  &.disabled {
    border-color: var(--grey-080);
  }

  &.white {
    background-color: white;
  }

  &.no-border {
    border-color: transparent;
  }
}

@mixin button-outline-checked {
  border-color: var(--primary-color-300);
  color: var(--primary-color-300);
  background-color: var(--primary-color-050);

  &:hover {
    border-color: var(--primary-color);
  }
}

@mixin button-small-size {
  font-size: var(--button-small-font-size);
  height: var(--button-small-height);
  padding: var(--button-small-padding);
  border-radius: var(--button-small-border-radius);

  .lefty-icon-i {
    font-size: var(--button-small-icon-size) !important;
  }

  &.icon {
    width: var(--button-small-height);
    min-width: var(--button-small-height);
  }
}

@mixin button-medium-size {
  height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-border-radius);

  &.icon {
    width: var(--button-height);
    min-width: var(--button-height);
  }
}

@mixin button-large-size {
  height: var(--button-large-height);
  padding: var(--button-large-padding);
  border-radius: var(--button-large-border-radius);

  &.icon {
    width: var(--button-large-height);
    min-width: var(--button-large-height);
  }
}
